import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { RadarChart } from "@components/Spider/RadarChart";
import { datasets, headerTexts } from "./mock";
import SwiperNavigation from "@components/ProductSwiper";
import DynamicComparisonBlock from "./ComparisonBlock";
import ProblemSolution from "./ProblemSolution";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";
import { GraphsService } from "@services/amazon/graph/graph.service";

export interface ProductComparisonProps {
  productOneTitles: string[];
  productTwoTitles: string[];
  productOneValues: number[];
  productTwoValues: number[];
}

const ProductComparison: React.FC<ProductComparisonProps> = ({
  productOneTitles,
  productTwoTitles,
  productOneValues,
  productTwoValues,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { selectedProduct } = useProductStore();
  const [spiderDots, setSpiderDots] = useState([]);
  const [problems, setProblems] = useState([]);
  const [activeProblemIndex, setActiveProblemIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex < headerTexts.length - 1 ? prevIndex + 1 : 0;
      setActiveProblemIndex(newIndex);
      return newIndex;
    });
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : headerTexts.length - 1;
      setActiveProblemIndex(newIndex);
      return newIndex;
    });
  };

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  };

  const handlePredictionInsightClick = () => {
    if (!isDragEnabled) {
      setIsTooltipVisible(true);
    } else {
      setIsTooltipVisible(false);
    }
    setIsDragEnabled(!isDragEnabled);
  };

  const dynamicRatingProduct1 = productOneValues?.[activeIndex];
  const dynamicRatingProduct2 = productTwoValues?.[activeIndex];
  const difference = (dynamicRatingProduct2 - dynamicRatingProduct1).toFixed(1);
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  const productOneTitleText = productOneTitles?.[activeIndex];
  const productTwoTitleText = productTwoTitles?.[activeIndex];
  const combinedHeaderText =
    productOneTitleText && productTwoTitleText
      ? productOneTitleText === productTwoTitleText
        ? productOneTitleText
        : `${productOneTitleText}, ${productTwoTitleText}`
      : productOneTitleText || productTwoTitleText;

  const dataValue = [{ productOneValues }, { productTwoValues }];
  const getDateRange = () => {
    const today = new Date();
    const date_end = today.toISOString().split("T")[0];

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 8);
    const date_start = oneMonthAgo.toISOString().split("T")[0];

    return { date_start, date_end };
  };
  const handleProductSelect = async () => {
    const spider = new SpiderService();
    const spiderDotsResponse = await spider.getProfuctInfo(
      selectedProduct.asin,
    );

    setSpiderDots(Array.isArray(spiderDotsResponse) ? spiderDotsResponse : []);
    setProblems(Array.isArray(spiderDotsResponse) ? spiderDotsResponse : []);
  };

  useEffect(() => {
    handleProductSelect();
  }, []);
  const handleSales = async () => {
    const spider = new SpiderService();
    const spiderD = await spider.getProfuctSpiderSales(selectedProduct.asin);
  };

  const handleReviewScore = async () => {
    const spider = new GraphsService();
    const { date_start, date_end } = getDateRange();
    const spiderDot = await spider.getGraph(
      selectedProduct.asin,
      date_start,
      date_end,
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.headContent}>
        <div className={styles.swiperBox}>
          <SwiperNavigation
            headerText={combinedHeaderText}
            onNext={handleNext}
            onPrev={handlePrev}
            showText={true}
            improvementValue={improvementValue}
          />
        </div>
        <DynamicComparisonBlock
          productScore={dynamicRatingProduct1}
          competitorScore={dynamicRatingProduct2}
          difference={difference}
        />
      </div>
      <div className={styles.comparison}>
        <ProblemSolution
          problems={spiderDots}
          onPredictionInsightClick={handlePredictionInsightClick}
          activeProblemIndex={activeProblemIndex}
        />
        <div className={styles.radarBox}>
          <RadarChart
            productOneTitles={productOneTitles}
            productTwoTitles={productTwoTitles}
            productOneValues={productOneValues}
            productTwoValues={productTwoValues}
            data={dataValue}
            datasets={datasets}
            activeIndex={activeIndex}
            onDotClick={handleDotClick}
            isDragEnabled={isDragEnabled}
            showTooltipWithArrow={isTooltipVisible}
            blockChanging={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductComparison;
