import React, { useEffect, useRef, useState } from "react";
import { Checkbox, List } from "antd";
import styles from "./style.module.scss";
import { SpiderService } from "@services/spider/spider.services";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductStore } from "src/store/overviewProduct.state";

export interface Product {
  id?: number;
  name?: string;
  title: string;
  asin: string;
  images?: string[];
  benefits?: string[];
}

const products: Product[] = [
  {
    title:
      "Crest 3D Whitestrips, Professional Effects Plus, Teeth Whitening Strip Kit, 48 Strips (24 Count Pack)",
    asin: "B09M5PXJ86",
  },
  {
    title:
      "Zimba Teeth Whitening Strips Vegan Whitening Strip Enamel Safe Teeth Whitening Hydrogen Peroxide Teeth Whitener for Coffee, Wine, Tobacco, and Other Stains, 28 Strips (14 Day Treatment), Mint",
    asin: "B07QC8LFQP",
  },
  {
    title:
      "Crest 3D Whitestrips, Professional Effects, Teeth Whitening Strip Kit, 44 Strips (22 Count Pack)",
    asin: "B00AHAWWO0",
  },
  {
    title:
      "Teeth Whitening Strips for Sensitive Teeth - 42 Strips Enamel Safe Non-Slip Dry Strip Technology for Whiter Teeth, 30 Minutes Fast Results, Non-Sensitive, 21 Treatments, Mint",
    asin: "B0CRDJ8J5P",
  },
  {
    title:
      "Lumineux Teeth Whitening Strips 7 Treatments - Enamel Safe - Whitening Without The Sensitivity - Dentist Formulated & Certified Non-Toxic",
    asin: "B093TSRPQM",
  },
];

interface ProductSelectionDropdownProps {
  isOpen: boolean;
  onSelect: (selectedProducts: Product[]) => void;
  onClose: () => void;
}

const MultiSelectModal: React.FC<ProductSelectionDropdownProps> = ({
  isOpen,
  onSelect,
  onClose,
}) => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const {
    selectedProduct,
    selectedProductToCompare,
    selectedProductToCompareMultiselect,
  } = useProductStore();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleProductSelection = (asin: string) => {
    if (selectedProducts.includes(asin)) {
      setSelectedProducts(selectedProducts.filter((item) => item !== asin));
    } else {
      setSelectedProducts([...selectedProducts, asin]);
    }
  };

  const handleApply = async () => {
    if (selectedProducts.length > 0) {
      const apiResponses = [];
      const spider = new SpiderService();

      const selectedProductDetails = products.filter((product) =>
        selectedProducts.includes(product.asin),
      );

      const additionalAsins = selectedProductToCompare
        .slice(0, 4)
        .map((product) => product.asin);

      const allAsins = [
        ...selectedProductDetails.map((product) => product.asin),
        ...additionalAsins,
        selectedProduct.asin,
      ];
      for (const product of selectedProductDetails) {
        try {
          const spiderDots = await AmazonService.products.getPublicProduct(
            product.asin,
          );

          const spiderDot = await spider.getSpiderDataList(...allAsins);
          apiResponses.push({ asin: product.asin, spiderDots });
        } catch (error) {
          console.error(`Error fetching data for ASINs:`, error);
        }
      }
      onSelect(apiResponses);
      selectedProductToCompareMultiselect(apiResponses);
      onClose();
    } else {
      console.log("No products selected");
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles["modal-dropdown"]} ref={modalRef}>
      <List
        dataSource={products}
        renderItem={(item) => (
          <div className={styles["list-item"]} key={item.asin}>
            <Checkbox
              checked={selectedProducts.includes(item.asin)}
              onChange={() => handleProductSelection(item.asin)}
              className={styles["checkbox"]}
            />
            <div style={{ marginLeft: 10 }}>
              <span className={styles["text"]}>{item.title}</span>
              <br />
              <span className={styles["text"]}>{item.asin}</span>
            </div>
          </div>
        )}
      />
      <button className={styles["apply-button"]} onClick={() => handleApply()}>
        Apply
      </button>
    </div>
  );
};

export default MultiSelectModal;
