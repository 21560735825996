import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { useProductsStore } from "src/store/product.state";
import {
  Product,
  ProductParams,
  ProductWithImageLink,
  ProductWithPictureUrl,
  SearchParams,
} from "./types";
import yourProductImage from "@assets/img/product1.png";
import competitorProductImage from "@assets/img/product2.png";

export class ProductsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  private formatParams(
    params: ProductParams | SearchParams,
  ): Record<string, string | number> {
    return Object.fromEntries(
      Object.entries(params).map(([key, value]) => [key, String(value)]),
    );
  }

  public async getOwnProducts(
    params: ProductParams = {},
  ): Promise<ProductWithImageLink[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithImageLink[]>(
      "/products/own",
      formattedParams,
    );
    response.map((product) => {
      if (product.image_link === null) {
        product.image_link = yourProductImage;
      }
    });
    useProductsStore.getState().setProductsOwn(response);
    return response;
  }

  public async searchOwnProducts(
    params: SearchParams,
  ): Promise<ProductWithImageLink[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithImageLink[]>(
      "/products/own/search",
      formattedParams,
    );
    response.map((product) => {
      if (product.image_link === null) {
        product.image_link = yourProductImage;
      }
    });
    useProductsStore.getState().setProductsOwn(response);
    return response;
  }

  public async getPublicProduct(
    asin: string[],
  ): Promise<ProductWithPictureUrl> {
    const product = await this.get<ProductWithPictureUrl>(
      `/products/public/${asin}`,
    );
    if (product.picture_url === null) {
      product.picture_url = competitorProductImage;
    }
    useProductsStore.getState().setProductPublic(product);
    return product;
  }
  public async postPublicProduct(
    ...asin: string[]
  ): Promise<ProductWithPictureUrl[]> {
    const path = "/products/public";

    const requestBody = {
      asin: asin.slice(0, 4),
    };
    const products = await this.post<ProductWithPictureUrl[]>(
      path,
      requestBody,
    );
    products.forEach((product) => {
      if (product.picture_url === null) {
        product.picture_url = competitorProductImage;
      }
      useProductsStore.getState().setProductPublic(product);
    });

    return products;
  }

  public async getPublicProducts(
    params: ProductParams = {},
  ): Promise<ProductWithPictureUrl[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithPictureUrl[]>(
      "/products/public",
      formattedParams,
    );
    response.map((product) => {
      if (product.picture_url === null) {
        product.picture_url = competitorProductImage;
      }
    });
    useProductsStore.getState().setProductsPublic(response);
    return response;
  }

  public async searchPublicProducts(
    params: SearchParams,
  ): Promise<ProductWithPictureUrl[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithPictureUrl[]>(
      "/products/public/search",
      formattedParams,
    );
    response.map((product) => {
      if (product.picture_url === null) {
        product.picture_url = competitorProductImage;
      }
    });
    useProductsStore.getState().setProductsPublic(response);
    return response;
  }
}
